import React from "react"
import Layout from "../../components/member/Layout"
import calendar from "../../components/member/Calendar/Calendar"

export default () => {
  var Calendar = calendar("reservation")
  const pageSlug = "reservation"
  const pageTitle = "施設利用"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Calendar />
    </Layout>
  )
}
